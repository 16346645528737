import { useEffect } from 'react';
import { navigate } from 'gatsby';

const RedirectTemplateToPage = () => {
  useEffect(() => {
    navigate('/');
  }, []);

  return null;
};

export default RedirectTemplateToPage;
